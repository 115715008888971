<template>
  <b-card
    class="cursor-pointer scroll-container d-flex flex-row-reverse justify-content-end overflow-auto px-1"
    no-body
  >
    <!-- Toa đầu tàu -->
    <div class="my-75 p-50 rounded-lg rounded-lg d-flex flex-column justify-content-end">
      <div style="margin-bottom: 1.6rem">
        <b-img
          :src="require(`@train/assets/train_head.svg`)"
          alt="Train Head"
          center
        />
      </div>
    </div>

    <template v-for="coach of stateTrainSelectedArray[itineraryIndex].coachOfTrains">
      <div
        :key="`coach-${coach.id}`"
        :class="`my-75 p-50 rounded-lg rounded-lg d-flex flex-column justify-content-between
        `"
        style="width: 200px"
        @click="selectCoachHandle(coach)"
      >
        <div
          :id="`coach-${coach.id}`"
          class="text-center fw-800 text-info text-nowrap"
        >
          Toa số {{ coach.coachNumber }}

          <b-tooltip
            :target="`coach-${coach.id}`"
            triggers="hover"
            placement="top"
            boundary="window"
            variant="danger"
          >
            <code class="fw-700 px-50 py-25">{{ coach.layoutId }}</code>
          </b-tooltip>
        </div>

        <div
          class="text-center"
          style="min-width: 130px;"
        >
          <small>
            {{ coach.description }} ({{ coach.code }})
          </small>
        </div>

        <div style="height: 95px;">
          <b-img
            :src="isSelectedCoach(coach.id) ? require(`@train/assets/train_body_active.svg`) : require(`@train/assets/train_body_default.svg`)"
            alt="Train"
            class="h-100"
            fluid
            center
          />
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BImgLazy, BImg, BTooltip,
} from 'bootstrap-vue'

import store from '@/store'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BImg,
    BImgLazy,
    BTooltip,
  },
  props: {
    // carriageData:
    itineraryIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const {
      loading,
      stateTrainSelectedArray,
      stateCoachSelectedArray,
    } = useTrainHandle()

    const isSelectedCoach = id => stateCoachSelectedArray.value.some(coach => coach.id === id)

    function selectCoachHandle(coach) {
      store.commit('app-train/SET_SELECTED_COACH', coach)
    }

    return {
      stateTrainSelectedArray,
      stateCoachSelectedArray,
      selectCoachHandle,
      isSelectedCoach,
    }
  },
}
</script>

<style lang="scss">
/* Custom scrollbar cho Chrome, Safari */
.scroll-container::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom scrollbar cho Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
</style>
