var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "cursor-pointer scroll-container d-flex flex-row-reverse justify-content-end overflow-auto px-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "my-75 p-50 rounded-lg rounded-lg d-flex flex-column justify-content-end"
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "1.6rem"
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@train/assets/train_head.svg"),
      "alt": "Train Head",
      "center": ""
    }
  })], 1)]), _vm._l(_vm.stateTrainSelectedArray[_vm.itineraryIndex].coachOfTrains, function (coach) {
    return [_c('div', {
      key: "coach-".concat(coach.id),
      class: "my-75 p-50 rounded-lg rounded-lg d-flex flex-column justify-content-between\n      ",
      staticStyle: {
        "width": "200px"
      },
      on: {
        "click": function click($event) {
          return _vm.selectCoachHandle(coach);
        }
      }
    }, [_c('div', {
      staticClass: "text-center fw-800 text-info text-nowrap",
      attrs: {
        "id": "coach-".concat(coach.id)
      }
    }, [_vm._v(" Toa số " + _vm._s(coach.coachNumber) + " "), _c('b-tooltip', {
      attrs: {
        "target": "coach-".concat(coach.id),
        "triggers": "hover",
        "placement": "top",
        "boundary": "window",
        "variant": "danger"
      }
    }, [_c('code', {
      staticClass: "fw-700 px-50 py-25"
    }, [_vm._v(_vm._s(coach.layoutId))])])], 1), _c('div', {
      staticClass: "text-center",
      staticStyle: {
        "min-width": "130px"
      }
    }, [_c('small', [_vm._v(" " + _vm._s(coach.description) + " (" + _vm._s(coach.code) + ") ")])]), _c('div', {
      staticStyle: {
        "height": "95px"
      }
    }, [_c('b-img', {
      staticClass: "h-100",
      attrs: {
        "src": _vm.isSelectedCoach(coach.id) ? require("@train/assets/train_body_active.svg") : require("@train/assets/train_body_default.svg"),
        "alt": "Train",
        "fluid": "",
        "center": ""
      }
    })], 1)])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }